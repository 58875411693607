import { FormatDateType, IdType, NameType, RolesType, SexType } from "./types";
import { izServicesApi } from "./api";
import { LocationType, orgEmployeesApi, PositionType } from "./apiEmployees";

// ------------------------------ ТЕКУЩИЙ ПОЛЬЗОВАТЕЛЬ

export const userApi = () => ({
  get: async (): Promise<CurrentUserType> => {
    const currentEmployee = (await izServicesApi.get(`/employeeService/getCurrentEmployee`)).data;

    const id = currentEmployee.personnelNumber;

    const employeeById = (await orgEmployeesApi().getEmployee(id)).data;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { orgId, ...employeeWithoutExcess } = employeeById;

    return { ...currentEmployee, ...employeeWithoutExcess, orgId };
  },
  update: async (props: CurrentUserSettingsCreateType) => {
    return izServicesApi.post(`/employeeService/setEmployeeSettings`, props);
  },
});

// ------------------------------ ТИПЫ - ТЕКУЩИЙ ПОЛЬЗОВАТЕЛЬ

// передаваемые значения

export type CurrentUserSettingsCreateType = {
  settings: string;
};

// получаемые значения

export type CurrentUserType = IdType &
  NameType & {
    personnelNumber: string;
    organization: string;
    departmentName: string;
    organizationName: string;
    department: string;
    roles: RolesType;
    imageInBase64: string;
    settings: string;
    sex?: SexType;
    birthDate: FormatDateType;
    address?: string;
    insuranceCode?: string;
    startPositionDate: FormatDateType;
    employmentDate?: FormatDateType;
    position?: PositionType;
    contacts: ContactsType;
    location?: LocationType;
  };

export type ContactsType = ContactType[];

export type ContactType = IdType & {
  isMain: boolean;
  type: "EMAIL" | "PHONE_NUMBER" | "WORK_NUMBER";
  value: string;
};
