import { AxiosResponse } from "axios";
import { FileLinkType } from "../utils/getFile";
import { getClearedObject } from "../utils/getClearedObject";
import { izApi, izServicesApi, psApi } from "./api";
import { AppointmentType } from "./apiAppointment";
import { HealthCenterType, MediumOtherType } from "./apiDictionaries";
import { InfectionDiseaseType } from "./apiInfectionDiseases";
import { entitiesBase, EntitiesResponseType } from "./apiRequest";
import { ContactsType } from "./apiUser";
import { VaccinationFactType } from "./apiVaccination";
import { FormatDateType, IdType, NameType, PaginationType, SexType } from "./types";
import { DiseasesType } from "./apiDisease";

// ------------------------------ КАРТОЧКА РАБОТНИКА

export const employeesApi = <E>() => ({
  ...entitiesBase<VaccinationEmployeeType, E, EmployeesRequestType>(izApi, "vs_Employee"),

  get: async (id: string): Promise<AxiosResponse<EmployeeType>> => {
    const employee = (await izApi.get(`/vs_Employee/${id}?fetchPlan=employee-fetch-plan`))
      .data as VaccinationEmployeeType;

    if (employee) {
      const orgEmployee = await orgEmployeesApi().getEmployee(employee.personnelNumber);
      const { data, ...orgEmployeeWithoutData } = orgEmployee;

      return { ...orgEmployeeWithoutData, data: { ...employee, ...data, status: employee.status } };
    } else {
      return Promise.reject(`Не найден работник "vs_Employee" по id = ${id}`);
    }
  },
});

export const orgEmployeesApi = <E>() => ({
  ...entitiesBase<EmployeeType, E, EmployeesRequestType>(psApi, "employees"),

  getAll: async (
    props: EmployeesRequestType
  ): Promise<AxiosResponse<EntitiesResponseType<EmployeeType>>> => {
    const startIndex = props?.startIndex;
    const size = props?.size;

    const params = {
      params: {
        ...getClearedObject(props),
        ...(startIndex ? { from: startIndex } : {}),
        ...(size ? { size } : {}),
      },
    };

    const response = psApi.get(`/employees/all`, params);
    const totalCountResponse = psApi.get(`/employees/total_count`, params);

    const items = (await response).data;
    const totalCount = (await totalCountResponse).data;

    return { ...response, data: { items, totalCount } };
  },
});

export const employeeServiceApi = <E>() => ({
  ...entitiesBase<EmployeeModuleType, E, EmployeePropsType>(izApi, "vs_EmployeeService"),
  getAll: async (
    props?: EmployeePropsType
  ): Promise<AxiosResponse<EntitiesResponseType<EmployeeModuleType>>> => {
    const response = await izServicesApi.post(`/employeeAppointmentDataServiceV2/getAll`, {
      request: props,
    });

    const serviceEmployee = response.data.data;
    const items: EmployeeModuleType[] = [];

    (serviceEmployee as EmployeeServiceType[]).forEach(
      ({ employee, appointmentVaccinations, settings, planned, previous }) => {
        items.push({
          id: employee.id,
          employee: { ...employee, positionName: employee.position as unknown as string },
          appointmentVaccinations,
          vaccinationSettings: settings,
          planned: planned,
          previous: previous,
        });
      }
    );

    const totalCount = response.data.page.size;

    return { ...response, data: { items, totalCount } };
  },
  getVaccinationData: async (
    props: EmployeePropsType
  ): Promise<AxiosResponse<EntitiesResponseType<EmployeeServiceType>>> => {
    const response = await izServicesApi.post(`/vaccinationDataService/getAll`, {
      request: getClearedObject(props),
    });

    const items = response.data.data;
    const totalCount = response.data.page.size;

    return { ...response, data: { items, totalCount } };
  },
  getInfectionData: async (
    props: EmployeePropsType
  ): Promise<AxiosResponse<EntitiesResponseType<EmployeeType>>> => {
    const response = await izServicesApi.post("/generalStatService/getEmployeeStat", {
      request: {
        ...props,
      },
    });

    const items = response.data.employee;
    const totalCount = response.data.size;

    return { ...response, data: { items, totalCount } };
  },
});

export const employeeVaccinationSettingsApi = <E>() =>
  entitiesBase<EmployeeVaccinationSettingType, E>(izApi, "vs_EmployeeVaccinationSettings");
export const medicalExemptionApi = <E>() =>
  entitiesBase<ExemptionType, E>(izApi, "vs_MedicalExemption");
export const antibodiesApi = <E>() => entitiesBase<AntibodiesType, E>(izApi, "vs_Antibodies");

export type VaccinationEmployeeType = IdType & {
  lastName: string;
  active: boolean;
  firstName: string;
  personnelNumber: string;
  middleName: string;
  department: string;
  email: string;
  username: string;
  status: "DEATH" | "SICK" | "HEALTH";
  vaccinationSettings?: EmployeeVaccinationsSettingType;
};

export type EmployeeServiceType = IdType & {
  access?: boolean;
  agreement?: boolean;
  exemption?: boolean;
  employee: EmployeeType;
  appointment?: AppointmentType;
  vaccinationFact?: VaccinationFactType;
  appointmentVaccinations?: AppointmentVaccinations;
  settings: EmployeeVaccinationSettingType & { exemptionId?: string };
  healthCenter: HealthCenterType;
  planned?: string;
  previous?: string;
};

// ------------------------------ ТИПЫ - РАБОТНИК СЕРВИС

export type EmployeeModuleType = IdType & {
  employee: EmployeeType;
  planned?: string;
  previous?: string;
  appointment?: AppointmentType;
  appointmentVaccinations?: AppointmentVaccinations;
  vaccinationFact?: VaccinationFactType;
  healthCenter?: HealthCenterType;
  vaccinationSettings?: EmployeeVaccinationSettingType;
};

export type AppointmentVaccinations = {
  appointment: AppointmentType;
  vaccinationFact?: VaccinationFactType;
}[];

// ------------------------------ ТИПЫ - РАБОТНИК

export type EmployeeType = IdType &
  NameType & {
    orgId: string;
    personnelNumber: string;
    sex?: SexType;
    birthDate: FormatDateType;
    position?: PositionType;
    positionName?: string;
    location?: LocationType;
    address?: string;
    insuranceCode?: string;
    startPositionDate: FormatDateType;
    employmentDate?: FormatDateType;
    category: MediumOtherType;
    citizenship: MediumOtherType;
    deathDate?: string;
    status: string;
    vaccinationSettings?: EmployeeVaccinationsSettingType;
    contacts: ContactsType;
    lastModifiedDate: string;

    //Сервис сотрудников
    department: DepartmentType;
    mainDepartment: DepartmentType;

    disease?: DiseasesType;
  };

export type EmployeeVaccinationsSettingType = EmployeeVaccinationSettingType[];

export type EmployeeVaccinationSettingType = IdType & {
  employee: EmployeeType;
  infection: InfectionDiseaseType;
  access: boolean;
  agreement: boolean;
  exemption: ExemptionsType;
  exemptionId?: string;
  antibodies: AntibodiesType[];
};

export type AntibodiesType = IdType & {
  date: string;
  description: string;
};

export type ExemptionsType = ExemptionType[];

export type ExemptionType = IdType & {
  date: string;
  description: string;
  document?: FileLinkType;
};

export type PositionType = {
  code: string;
  name: string;
  description: string;
  shortName: string;
  department: DepartmentType;
};

type DepartmentType = {
  code: string;
  mainDepartment: string;
  mainDepartmentName: string;
  name: string;
  shortName: string;
  type: string;
  organization: OrganizationType;
  parent?: string;
  location?: LocationType;
};

type OrganizationType = {
  code: string;
  name: string;
};

export type LocationType = {
  country: string;
  region: string;
  city: string;
  street: string;
  house: string;

  name: string;
};

// передаваемые значения

export type EmployeesRequestType = PaginationType & {
  searchQuery?: string;
  year?: number;
  mainDepartment?: string;
  organization?: string;
  status?: string;
  export?: string;
  disabilityId?: string;
  diseaseClassId?: string;
  startDateOfDiseasePeriod?: FormatDateType;
  endDateOfDiseasePeriod?: FormatDateType;
};

export type EmployeePropsType = {
  infectionId?: string;
  vaccinationPlanId?: string;
  departmentCode?: string;
  hasAppointment?: boolean | string;
  agreement?: boolean | string;
  access?: boolean | string;
  exemption?: boolean | string;
  plannedExpired?: boolean | string;
  plannedComes?: boolean | string;
  textSearch?: string;
  page?: number;
  size?: number;

  startDate?: string;
  endDate?: string;
  healthCenter?: string;
  organization?: string;
  department?: string;
  expired?: string;
};
