import { useAtomValue, useUpdateAtom } from "jotai/utils";
import { exportFormState } from "../../../../atoms/atoms";
import React from "react";
import { NavigateButton, UniversalTextButton } from "../../../../uiKit/Buttons";
import { useInfectionDiseaseOptions, useMainDepartmentOptions } from "../../../../utils/getOptions";
import { filtersState } from "../../utils/atoms";
import { Download } from "../../../../uiKit/Icons";
import { HeaderNavigation } from "../../../../uiKit";
import { useData as useStatisticData } from "../../../VaccinationStatisticPage/hooks/useData";
import { INFOGRAPHIC_VACCINATION } from "../../../../constants/routes";
import { currentDateInterval } from "../../../VaccinationStatisticPage/utils/atoms";

export const useData = () => {
  const statisticData = useStatisticData();

  // ------------------------------ АТОМЫ

  const { roleModel, res, setDateSwitcherType } = statisticData;
  const setExportFormIsOpen = useUpdateAtom(exportFormState);
  const mainDepartmentOptions = useMainDepartmentOptions();
  const infectionOptions = useInfectionDiseaseOptions();
  const filters = useAtomValue(filtersState);

  // ------------------------------ КОНСТАНТЫ

  const { infection, department } = filters;
  const {
    canViewInfographicVaccinationEmployees: canView,
    canDownloadInfographicVaccination: canDownload,
    canViewEmployeeCard,
  } = roleModel;

  // ------------------------------ ШАПКА

  const departmentTitle =
    mainDepartmentOptions.find(({ id }) => id === department)?.name ?? "Назад";

  const infectionTitle = infectionOptions.find(({ id }) => id === infection)?.name ?? "";

  const organizationTitle = res.organizationName.replaceAll('"', "") ?? "";

  const exportButton = canDownload ? (
    <UniversalTextButton
      text="Выгрузить статистику"
      iconStart={<Download />}
      type="primary"
      onClick={() => setExportFormIsOpen(true)}
    />
  ) : undefined;

  const navigation = (
    <HeaderNavigation
      title={{
        text: "Статистика по подразделению",
        element: (
          <NavigateButton
            link={INFOGRAPHIC_VACCINATION("department", infection)}
            title={`${departmentTitle}`}
            from="Инфографика"
            subTitle={infectionTitle}
          />
        ),
      }}
      section={{
        name: `Инфографика / ${organizationTitle}`,
        rout: INFOGRAPHIC_VACCINATION("department", infection),
      }}
      needSearchButton
      needFilterButton
      needDateSwitcher={{ dateIntervalAtom: currentDateInterval, typeInterval: "oneDate" }}
      buttonsStart={exportButton}
    />
  );

  return {
    ...statisticData,

    canView,
    navigation,

    setDateSwitcherType,

    canViewEmployeeCard,
  };
};

export type MorbidityType = {
  diseasesClass: string;
  infectionName: string;
  period: string;
};
