import { useAtomValue, useUpdateAtom } from "jotai/utils";
import { currentUserAtom } from "../../../../atoms/atomCurrentUser";
import { dateSwitcherTypeState, exportFormState } from "../../../../atoms/atoms";
import { useInfectionDiseaseOptions, useMainDepartmentOptions } from "../../../../utils/getOptions";
import { filtersState } from "../../utils/atoms";
import {
  currentDateInterval,
  infectionEmployeesState,
  useInfectionEmployeesKeys,
} from "../utils/atoms";
import { NavigateButton, UniversalTextButton } from "../../../../uiKit/Buttons";
import { Download } from "../../../../uiKit/Icons";
import React from "react";
import { HeaderNavigation } from "../../../../uiKit";
import { INFOGRAPHIC_DISEASES } from "../../../../constants/routes";
import { useLoadableData } from "../../../../utils/useLoadableData";

export const useData = () => {
  // ------------------------------ АТОМЫ

  const { roleModel, res } = useAtomValue(currentUserAtom);
  const setDateSwitcherType = useUpdateAtom(dateSwitcherTypeState);
  const setExportFormIsOpen = useUpdateAtom(exportFormState);
  const mainDepartmentOptions = useMainDepartmentOptions();
  const infectionOptions = useInfectionDiseaseOptions();
  const filters = useAtomValue(filtersState);

  const { loadableData, data, totalCount } = useLoadableData(infectionEmployeesState, []);

  // ------------------------------ КОНСТАНТЫ

  const { api, queryKey } = useInfectionEmployeesKeys();
  const { infection, department } = filters;
  const {
    canViewInfographicInfectionEmployees: canView,
    canDownloadInfographicVaccination: canDownload,
    canViewDepartmentChart,
    canViewProfile,
  } = roleModel;

  // ------------------------------ ШАПКА

  const departmentTitle =
    mainDepartmentOptions.find(({ id }) => id === department)?.name ?? "Назад";

  const infectionTitle = infectionOptions.find(({ id }) => id === infection)?.name ?? "";

  const organizationTitle = res.organizationName.replaceAll('"', "") ?? "";

  const link = canViewDepartmentChart
    ? INFOGRAPHIC_DISEASES("department", infection)
    : INFOGRAPHIC_DISEASES("organization");

  const exportButton = canDownload ? (
    <UniversalTextButton
      text="Выгрузить статистику"
      iconStart={<Download />}
      type="primary"
      onClick={() => setExportFormIsOpen(true)}
    />
  ) : undefined;

  const navigation = (
    <HeaderNavigation
      title={{
        text: "Назад",
        element: (
          <NavigateButton
            link={link}
            title={departmentTitle}
            from="Инфографика"
            subTitle={infectionTitle}
          />
        ),
      }}
      section={{
        name: `Инфографика / ${organizationTitle}`,
        rout: link,
      }}
      needSearchButton
      needFilterButton
      needDateSwitcher={{ dateIntervalAtom: currentDateInterval, typeInterval: "oneDate" }}
      buttonsStart={exportButton}
    />
  );

  return {
    departmentTitle,
    infectionTitle,
    organizationTitle,

    data,
    loadableData,
    totalCount,

    canView,
    navigation,

    queryKey,
    api,

    setDateSwitcherType,

    canViewProfile,
  };
};
